module services {
    export module applicationcore {
        export class entityService implements interfaces.applicationcore.IEntityService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getAzureOCRTemplateList(entityId: number): angular.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
             return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Entity/GetAzureOCRTemplateList", {
                    entityId: entityId
                });
            }
            
            getEntityAdditionalSettings(): angular.resource.IResourceClass<interfaces.applicationcore.EntityAdditionalSettings> {
                return this.$resource<interfaces.applicationcore.EntityAdditionalSettings>(this.ENV.DSP_URL + "Entity/GetEntityAdditionalSettings", {
                    entityId: "@entityId"
                    });
            }
            
            
            getUserEntities(): ng.resource.IResourceClass<interfaces.applicationcore.IEntityTreeListItem> {
                var getEntities: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                };

                return this.$resource<interfaces.applicationcore.IEntityTreeListItem>(this.ENV.DSP_REQUEST_URL_RIGHTS + "GetEntities", {
                        licensedOnly: '@licensedOnly'
                    }, {
                            query: getEntities
                        });
            }

            setUserEntity(entityId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_REQUEST_URL_RIGHTS + "ChangeEntity", {
                    entityId: entityId
                });
            }

            getCurrentEntity(): ng.resource.IResourceClass<interfaces.applicationcore.IEntity> {
                var getCurrent: ng.resource.IActionDescriptor = {
                    method: 'GET'
                };

                return this.$resource<interfaces.applicationcore.IEntity>(this.ENV.DSP_REQUEST_URL_RIGHTS + "GetCurrentEntity", {

                    }, {
                            query: getCurrent
                        });
            }

            getList(): ng.resource.IResourceClass<interfaces.applicationcore.IEntity> {
                var getList: ng.resource.IActionDescriptor = {
                    method: 'GET',
                    isArray: true
                };

                return this.$resource<interfaces.applicationcore.IEntity>(this.ENV.DSP_URL + "Entity/GetList", {

                    }, {
                            query: getList
                        });
            }

            getEntityCountry(): ng.resource.IResourceClass<interfaces.applicationcore.ICountry> {
                return this.$resource<interfaces.applicationcore.ICountry>(this.ENV.DSP_URL + "Entity/GetEntityCountry", {
                    entityId: "@entityId"
                    });
            }

            getDropdownList(searchText: string, currentEntityId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Entity/GetDropdownList", {
                    searchText: searchText,
                    currentEntityId: currentEntityId
                });
            }
        }
    }
    angular.module("app").service("entityService", services.applicationcore.entityService);
}